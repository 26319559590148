.progress-title {
  text-align: center;
  margin-bottom: 10px;
}

.progress-bar-driver {
  height: 6px;
  background-color: #f3f3f3;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
}

.progress-fill {
  height: 100%;
  transition: width 0.3s ease;
  position: absolute;
  z-index: 1;
}

.progress-overflow {
  height: 100%;
  background-color: red;
  transition: width 0.3s ease;
  position: absolute;
  z-index: 2;
  right: 0;
}

.progress-text {
  text-align: center;
  margin: 0;
}
