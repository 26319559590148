@font-face {
  font-family: GtIcons;
  src: url("../../../font/GTFleetIcons.ttf");
}

.svg {
  float: left;
}

.svg span {
  display: block;
  display: flex;
  text-align: center;
  align-items: center;
  font-family: GtIcons, sans-serif;
  user-select: none;
}

.centerLoader {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centerLoader span {
  animation: spin 3s linear infinite;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* List of all icons name unicode for GTFleetIcons.ttf:
  
  activity = 0xe800;
  add = 0xe802;
  add_view = 0xe801;
  air_filter = 0xe803;
  alarm_siren = 0xe99;
  alert = 0xe805;
  alert_v2 = 0xe804;
  alerts = 0xe806;
  arrow_down = 0xe807;
  arrow_left = 0xe808;
  arrow_right = 0xe809;
  arrow_to_left = 0xe80a;
  arrow_to_right = 0xe80b;
  arrow_up = 0xe80c;
  attach = 0xe80d;
  attention = 0xe80e;
  automations = 0xe80f;
  average_speed = 0xe810;
  back = 0xe811;
  battery = 0xe88e;
  bookmark = 0xe812;
  brake_parts = 0xe813;
  build = 0xe814;
  calendar = 0xe815;
  call_center = 0xe816;
  car = 0xe81a;
  car_insurance = 0xe817;
  car_repair = 0xe818;
  car_tax__bollo_ = 0xe819;
  check = 0xe81b;
  clock = 0xe81c;
  close = 0xe81d;
  CloseToLeft = 0xe90c;
  cloud_update = 0xe81e;
  CornerUpLeft = 0xe90e;
  CornerUpRight = 0xe90d;
  credit_balance = 0xe920;
  dashboard = 0xe81f;
  devices = 0xe820;
  diagnosis_report = 0xe814;
  DoorBackOpen = 0xe904;
  DoorBackClosed = 0xe8b4;
  down = 0xe822;
  down_point = 0xe8aa
  download = 0xe823;  
  draggable = 0xe8a5;
  driver_behaviour = 0xe824;
  drivers = 0xe825;
  edit = 0xe826;
  end_point = 0xe827;
  EndB = 0xe903;
  engine_lock = 0xe828;
  euro = 0xe829;
  Exchange = 0xe905;
  expand_map = 0xe82a;
  external_link = 0xe82b;
  eye = 0xe88a;
  eye_slash = 0xe88b;
  file_report = 0xe82c;
  filter = 0xe82d;
  finish_b = 0xe82e;
  forward = 0xe82f;
  full_screen = 0xe830;
  gas_station = 0xe831;
  generical_inspection = 0xe832;
  GeofenceLine = 0xe907;
  GeofenceOval = 0xe90f;
  GeofencePolygon = 0xe908;
  geofences = 0xe833;
  group = 0xe834;
  help = 0xe836;
  home_work = 0xe837;
  kilometers = 0xe838;
  layers = 0xe897;
  licence = 0xe83a;
  list = 0xe83b;
  loader = 0xe83c;
  location_history = 0xe83d;
  locator_off = 0xe83e;
  locker_open = 0xe83f;
  logs = 0xe840;
  mail = 0xe841;
  maintenance_and_deadlines = 0xe842;
  map = 0xe843;
  menu = 0xe848;
  menu_dashboard = 0xe844;
  menu_help = 0xe895;
  menu_profile = 0xe846;
  menu_routes = 0xe847;
  messages = 0xe849;
  minus = 0xe84a;
  missions = 0xe84b;
  mobile_device = 0xe88c;
  moon = 0xe84c;
  more = 0xe84d;
  notification = 0xe84e;
  odometer = 0xe84f;
  OpenToRight = 0xe900;
  order_by = 0xe850;
  parking = 0xe851;
  parking_protection_1 = 0xe88f;
  periodical_inspection = 0xe893;
  permissions = 0xe853;
  phone = 0xe854;
  poll = 0xe855;
  pollen_filter = 0xe856;
  power = 0xe857;
  radar = 0xe881;
  refresh = 0xe858;
  Reorder = 0xe901;
  repeat = 0xe859;
  scale_down = 0xe8a4;
  search = 0xe85a;
  security = 0xe85b;
  ShoppingCart = 0xe906;
  signal = 0xe88d;
  Snow = 0xe90b;
  solid_alert = 0xe922;
  solid_cast = 0xe85c;
  solid_change_oil = 0xe85d;
  solid_dashboard = 0xe85e;
  solid_diesel_filter = 0xe85f;
  solid_distance = 0xe860;
  solid_dollar = 0xe8ac  
  solid_double_check = 0xe821;
  solid_driver = 0xe861;
  solid_engine = 0xe862;
  solid_flag = 0xe863;
  solid_glow_plug = 0xe864;
  solid_groups = 0xe8a7;
  solid_gt = 0xe865;
  solid_key = 0xe866;
  solid_locator = 0xe867;
  solid_locker_closed = 0xe868;
  solid_marker = 0xe869;
  solid_phone = 0xe86a;
  solid_pin = 0xe86b;
  solid_pound = 0xe8ab
  solid_road = 0xe86c;
  solid_satellite = 0xe8a6;
  solid_settings = 0xe86d;
  solid_success = 0xe86e;
  solid_user = 0xe86f;
  solid_wheel_change = 0xe872;
  solid_wheel_change_summer = 0xe870;
  solid_wheel_change_winter = 0xe871;
  speed = 0xe875;
  speed_camera = 0xe873;
  speed_limit = 0xe874;
  start_a = 0xe876;
  start_point = 0xe877;
  stop_point = 0xe878;
  sun = 0xe879;
  sync_icon = 0xe87a;
  tachometer = 0xe87b;
  target = 0xe87c;
  Temperature = 0xe909;
  Humidity = 0xe8b2;
  terrain = 0xe87d;
  theft_protection = 0xe87e;
  timer = 0xe87f;
  TrailerTruck = 0xe90a;
  trash = 0xe880;
  trophy = 0xe891;
  truck = 0xe882;
  up = 0xe883;
  up_point = 0xe8a9
  Upload = 0xe902
  user = 0xe884;
  utilization = 0xe886;
  vehicle = 0xe888;
  video = 0xe887;
  warning = 0xe889;
  external_battery = 0xe8ae;
  motorcycle = 0xe8ad;
  whatsapp = 0xe835;
  stop = 0xe8af;
  stopF = 0xe839;
  operating_machine = 0xe8b0;
  bus = 0xe8b1;
  humidity = 0xe8b3
*/
