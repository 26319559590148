.beacon-threshold-row-form {
  display: flex;
  justify-content: space-between;
  margin: 10px 10px;
}

.custom-threshold-modal-footer {
  margin: 20px 20px;
  display: flex;
  justify-content: space-between;
}
