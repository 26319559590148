/* Table */
.table-tags {
  margin: 2px 9px 2px 0;
}
/*End Table*/

th#services {
  min-width: 350px;
}

.ev-table th {
  min-width: 130px;
}

/*Toast*/
#tableVehicleErrorToast {
  background: var(--global-colors-feedback-danger);
  color: var(--global-colors-ui-white);
}

#tableVehicleErrorToast .Toastify__progress-bar {
  background: var(--global-colors-ui-white);
}

#tableVehicleErrorToast svg {
  fill: var(--global-colors-ui-white);
}
/* End Toast */

/*Only view*/
.ev-only-view {
  pointer-events: none;
  opacity: 0.7;
}

.ev-only-view input {
  border: 1px solid var(--global-colors-sky-dark);
  color: var(--global-colors-ink-light);
}

/*End Only view*/

/* Topbar */
.ev-subtopbar {
  height: 82px;
  width: 100%;
  margin: 0;
  background-color: var(--global-colors-ui-white);
  border-bottom: 1px solid #d2d5da;
  padding: 25px 24px;
  align-items: center;
}

.ev-subsection-name {
  max-height: 28px;
  color: var(--global-colors-ink-dark);
  font-family: var(--global-font-regular);
  font-size: 24px;
  letter-spacing: 0;
  line-height: 28px;
  margin: 0;
  float: left;
}

.ev-button-save-group {
  float: right;
}

.ev-button-save-group .button {
  min-width: 57px;
  min-height: 32px;
  height: 32px;
  width: auto;
}
/* Forms */

.ev-form-container {
  position: relative;
  top: 0;
  background-color: var(--global-colors-sky-lighter);
  width: auto;
  height: 100%;
  overflow-y: scroll;
}

.ev-forms-title {
  font-family: var(--global-font-medium);
  font-size: var(--global-font-size-sm);
  width: 153px;
  height: 18px;
  margin-top: 36px;
  margin-left: 36px;
}

.ev-forms-container {
  height: 42px;
  margin: 24px 77px 0px 36px;
}

.ev-forms-sensors-container {
  margin: 24px 77px 0px 36px;
}

.ev-forms-sensors-container .widget-no-child-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
}

.ev-forms-sensors-container .widget-body {
  width: 100%;
  display: block;
  height: 288px;
  max-height: calc((100vh - 350px));
  overflow-y: auto;
}

.ev-forms-container:last-child {
  margin-bottom: 36px;
}

.ev-tag-forms-container {
  margin: 24px 77px 36px 36px;
  height: fit-content;
  display: flex;
}

.ev-forms-label {
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  color: var(--global-colors-ink-light);
  width: 120px;
  float: left;
  height: 100%;
  display: flex;
  align-items: center;
}
.ev-forms-label-right {
  font-family: var(--global-font-semi-bold);
  font-size: var(--global-font-size-xs);
  width: 120px;
  float: left;
  height: 100%;
  display: flex;
  align-items: center;
}

.ev-forms {
  width: calc(100% - (85px + 110px));
  float: right;
  margin-left: 75px;
}

.ev-left-form {
  width: 49%;
  height: 42px;
  float: left;
}

.ev-right-form {
  width: 49%;
  height: 42px;
  float: right;
  margin-left: 16px;
}

.ev-left-form-col {
  width: 33.33%;
  padding-right: 15px;
  height: 42px;
  float: left;
}

.ev-middle-form-col {
  width: 33.33%;
  height: 42px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ev-right-form-col {
  width: 33.34%;
  height: 42px;
  float: right;
}
/* End Topbar */

/* General Info Form */

.ev-general-info-form {
  margin: 16px 16px;
  box-sizing: border-box;
  width: auto;
  background-color: var(--global-colors-ui-white);
  border: 1px solid #e4e6e9;
  border-radius: 4px;
}

/* End General Info Form */

/* Specs Info Form */

.ev-specs-info-form {
  margin-left: 16px;
  margin-top: 16px;
  margin-right: 16px;
  box-sizing: border-box;
  width: auto;
  background-color: var(--global-colors-ui-white);
  border: 1px solid #e4e6e9;
  border-radius: 4px;
}

/* End Specs Info Form */

/* Driver Form */

.ev-driver-info-form {
  margin-left: 16px;
  margin-top: 16px;
  margin-right: 16px;
  box-sizing: border-box;
  width: auto;
  background-color: var(--global-colors-ui-white);
  border: 1px solid #e4e6e9;
  border-radius: 4px;
}

/* End Driver Form */

/* Device Form */

.ev-device-info-form {
  margin-left: 16px;
  margin-top: 16px;
  margin-right: 16px;
  box-sizing: border-box;
  width: auto;
  background-color: var(--global-colors-ui-white);
  border: 1px solid #e4e6e9;
  border-radius: 4px;
}

.ev-forms-slider {
  width: 264px;
  float: left;
  margin-top: 5px;
}

.ev-forms .ev-forms-slider .slider-container {
  margin: 0;
}

.ev-forms .ev-forms-slider .value-label-container {
  width: 63.6px;
}

/* End Device Form */
/* Sensor Form */

.ev-sensor-info-form {
  margin-left: 16px;
  margin-top: 16px;
  margin-right: 16px;
  box-sizing: border-box;
  width: auto;
  height: auto;
  background-color: var(--global-colors-ui-white);
  border: 1px solid #e4e6e9;
  border-radius: 4px;
}

/* End Sensor Form */

/* Service Form */
.ev-service-info-form {
  margin-left: 16px;
  margin-top: 16px;
  margin-right: 16px;
  box-sizing: border-box;
  background-color: var(--global-colors-ui-white);
  border: 1px solid #e4e6e9;
  border-radius: 4px;
}

.ev-service-info-form:last-child {
  margin-bottom: 16px;
}

.ev-service-info-form button {
  margin-left: 12px;
  width: 180px;
}

.ev-forms-credits form {
  display: flex;
}

.ev-forms-credits .text-field {
  width: 100%;
}
.edit-tags {
  margin: 0 9px 9px 0;
}
/* End Device Form /*

/* Custom Logo Form */

.ev-custom-logo-form {
  margin: 16px;
  box-sizing: border-box;
  height: 222px;
  width: auto;
  background-color: var(--global-colors-ui-white);
  border: 1px solid #e4e6e9;
  border-radius: 4px;
}

/* Splitter Bar */

.ev-splitter {
  position: absolute;
  top: 1386px;
  left: 52px;
  right: 52px;
  height: 1px;
  background-color: var(--global-colors-sky-sky);
}

/* Dropdown Small */

.ev-drpdwn-small > :last-child {
  width: 100%;
}

/* Dropdown Large */

.ev-drpdwn-large > :last-child {
  width: 100%;
}

/* Button Group */

.ev-bg .btn-group {
  width: 100%;
}

.ev-bg .btn-group .button {
  width: 50%;
}

/*#region table or pagination style*/
.ev-table {
  margin-bottom: 70px;
}

.ev-table
  table
  tbody
  tr
  .dropdown-btn
  > .dropdown__button--ghost
  > .dropdown-only-icon {
  margin-left: 0px;
}

.ev-table
  table
  tbody
  tr:nth-last-child(-n + 2):not(:first-child)
  .dropdown-btn.dropdown--ghost
  .dropdown-list-only-icon:not(.list-two):not(.list-one) {
  top: -129px;
}

.ev-table
  table
  tbody
  tr:last-child:not(:only-child)
  .dropdown-btn
  .dropdown-list-only-icon
  .list-one {
  top: -46px;
}

.ev-table
  table
  tbody
  tr:last-child:not(:only-child)
  .dropdown-btn
  .dropdown-list-only-icon
  .list-two {
  top: -88px;
}

.ev-pagination {
  position: fixed;
  bottom: 0;
  z-index: 3;
  /* for Chrome, Edge, Opera */
  min-width: -webkit-fill-available !important;
  max-width: -webkit-fill-available !important;
  /* for Mozilla */
  min-width: -moz-available !important;
  max-width: -moz-available !important;
}
/*#endregion table or pagination style*/

.ev-right-form-col
  .mn-select.mn-select--is-open
  .mn-select__inner
  .mn-select__options {
  width: inherit;
  box-shadow: var(--global-elevation-02);
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  border: 1px solid var(--global-colors-sky-sky);
  background-color: var(--global-colors-ui-white);
  position: absolute;
  top: 100%;
  left: 0;
  outline: 0;
  margin-top: var(--global-spacing-xs);
  height: 350px;
  overflow-y: auto;
  z-index: 9;
}
